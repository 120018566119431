<template>
  <v-container fluid>
       <v-row no-gutters>
        <v-col cols="12" md="12">
          <Userinfo/>
        </v-col>
     <!--  <v-col cols="12" md="12">
        <PersonnelIvenCount/>
         </v-col>    
         <v-col cols="12" md="12">
            <studentStd/>
         </v-col> -->
    </v-row>   
  </v-container>
</template>

<script>
import PersonnelIvenCount from '../../components/supervisions/personnelIvenCount.vue';
import studentStd from '../../components/supervisions/studentStd.vue';
import Userinfo from '../../components/userinfo.vue';

export default {
    name: "DashboardDashboard",
    data() {
        return {};
    },
    async mounted() {
    },
    methods: {},
    components: { PersonnelIvenCount, studentStd, Userinfo }
};
</script>
